import {
  THomeEventFilter,
  THomeEventFilterGroup,
} from "src/data/events/types/homeEventTypes"
import { useSearchParams } from "src/router/useSearchParams"

export function useHomeLogParams() {
  const { searchParams, setSearchParams } = useSearchParams({
    keys: [
      { key: "eventFilter", type: "string", list: true },
      { key: "startDate", type: "string" },
      { key: "endDate", type: "string" },
    ],
  })

  function isHomeEventFilter(item: unknown): item is THomeEventFilterGroup {
    return (
      typeof item === "string" &&
      THomeEventFilter.some((filter) => filter === item)
    )
  }

  const eventFilter: THomeEventFilterGroup[] = (
    searchParams.eventFilter ?? []
  ).filter(isHomeEventFilter)
  function setEventFilter(newEventFilter: THomeEventFilterGroup[]) {
    setSearchParams("eventFilter", newEventFilter)
  }

  const startDate = searchParams.startDate
    ? new Date(searchParams.startDate)
    : null
  function setStartDate(newStartDate: Date | null) {
    setSearchParams("startDate", newStartDate?.toISOString() ?? null)
  }

  const endDate = searchParams.endDate ? new Date(searchParams.endDate) : null
  function setEndDate(newEndDate: Date | null) {
    setSearchParams("endDate", newEndDate?.toISOString() ?? null)
  }

  return {
    eventFilter,
    setEventFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  }
}
