import { useState } from "react"
import styled from "styled-components"

import { UpgradeBlockerButton } from "src/components/FeatureBlockers/UpgradeBlockerButton"
import { Violations } from "src/data/homes/types/homeTypes"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import InfoIcon from "src/ui/icons/info.svg"
import { MLink } from "src/ui/Link/MLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

function violationText(vType: Violations) {
  switch (vType) {
    case "multiple_homes_in_one_virtual_home":
      return (
        <>
          <MText variant="heading3"> Multiple listings in this home</MText>
          <StyledBodyText>
            <P>
              Create a new home per listing and re-install or transfer your
              sensors via the web app to keep using Minut.
            </P>
            <P>
              The price of each subscription plan is specified per listing. A
              listing is an individual living space such as a house or an
              apartment.
            </P>
          </StyledBodyText>
        </>
      )
    case "number_of_members":
      return (
        <>
          <MText variant="heading3">
            Paid plan required for multiple users
          </MText>
          <StyledBodyText>
            The homeowner needs a paid plan to allow multiple users.
          </StyledBodyText>
        </>
      )
    case "number_of_homes":
      return (
        <>
          <MText variant="heading3">
            Paid plan required for multiple homes
          </MText>
          <StyledBodyText>
            The homeowner needs a paid plan to allow multiple homes.
          </StyledBodyText>
        </>
      )
    default:
      return null
  }
}

export function ViolationBanner({ violations }: { violations?: Violations[] }) {
  const [moreModalOpen, setMoreModalOpen] = useState(false)
  if (!violations?.length) return null

  function ViolationModal() {
    const title = `Update your plan`
    const descr = `
      You will not receive any notifications and will not be able to use
      noise monitoring or the security alarm.
    `
    return (
      <ConfirmDialog
        open={moreModalOpen}
        title={title}
        description={descr}
        onClose={() => setMoreModalOpen(false)}
        onConfirm={() => {}}
      >
        <>
          <ModalContents>
            {violations?.map((vType, index) => {
              return (
                <ViolationItem key={index}>
                  <InfoIcon
                    height={24}
                    width={24}
                    color={mColors.systemErrorDark}
                  />
                  {violationText(vType)}
                </ViolationItem>
              )
            })}
          </ModalContents>
          <div>
            If you have any questions, contact support at{" "}
            <MLink href="mailto:hello@minut.com">hello@minut.com</MLink> for
            further assistance.
          </div>
        </>
      </ConfirmDialog>
    )
  }

  return (
    <Box>
      <ViolationModal />
      <InfoIcon width={32} color={mColors.systemErrorDark} />
      <div style={{ flexGrow: 1 }}>
        <MText variant="heading3" color="emergency">
          Update your plan
        </MText>
        <StyledBodyText color="emergency">
          You will not receive any notifications
        </StyledBodyText>
      </div>
      <MButton variant="minimal" onClick={() => setMoreModalOpen(true)}>
        Read more
      </MButton>

      <UpgradeBlockerButton eventProperties={{ context: "violation_banner" }} />
    </Box>
  )
}

const Box = styled.div`
  color: ${mColors.systemErrorDark};
  background: ${mColors.systemError};
  padding: ${spacing.XL};
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.XL};
  align-items: center;
  border-radius: 14px;
  margin-bottom: ${spacing.XL2};
`

const StyledBodyText = styled(MText).attrs(() => ({ variant: "body" }))`
  grid-column: 2;
  display: grid;
  row-gap: ${spacing.M};
`

const ModalContents = styled.div`
  display: grid;
  margin: ${spacing.XL} 0;
  gap: ${spacing.M};
`

const ViolationItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${spacing.M};
`

const P = styled.p`
  margin: 0;
`
