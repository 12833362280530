import styled from "styled-components"

import { AdjustableHomeMap } from "src/components/Homes/HomeAddress/AdjustableHomeMap"
import {
  formatPostalAddress,
  formatStreetname,
} from "src/components/Homes/HomeDetails/Overview/addressUtils"
import { ILocation, THomeAddress } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MButton } from "src/ui/Button/MButton"
import InfoIcon from "src/ui/icons/info.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeAddress({
  homeId,
  location,
  address,
  editable,
}: {
  homeId: string
  location?: ILocation
  address?: THomeAddress
  editable?: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="heading3">
        {t(langKeys.home_address_wizard_auto_complete_label)}
      </MText>

      {address && (
        <>
          <MText variant="subtitle" color="secondary">
            {formatStreetname(address)}
          </MText>
          <MText color="secondary">{formatPostalAddress(address)}</MText>
          {address.floor_number && (
            <MText color="secondary">
              {t(langKeys.home_address_floor)} {address.floor_number}
            </MText>
          )}
          {address.apartment_number && (
            <MText color="secondary">
              {t(langKeys.home_address_apt_room)} {address.apartment_number}
            </MText>
          )}
        </>
      )}

      {(!address || !location) && <MissingAddressBanner />}

      {location && (
        <MapWrapper>
          <AdjustableHomeMap
            showTooltip={false}
            showAdjustButton={false}
            height="200px"
            markerWidth="30px"
            location={{
              latitude: location?.latitude || 0,
              longitude: location?.longitude || 0,
            }}
          />
        </MapWrapper>
      )}

      <ButtonWrapper>
        <MButton
          variant="subtle"
          disabled={!editable}
          appHref={Routes.EditHomeAddress.location(homeId)}
        >
          {t(langKeys.home_change_address)}
        </MButton>
      </ButtonWrapper>
    </div>
  )
}

export function MissingAddressBanner() {
  const { t, langKeys } = useTranslate()

  return (
    <MBanner
      icon={<InfoIcon width={24} color="unset" />}
      type="warning"
      fullWidth
      title={t(langKeys.home_settings_missing_address_info)}
    />
  )
}

const MapWrapper = styled.div`
  max-width: 600px;
  margin-block: ${spacing.M};
`

const ButtonWrapper = styled.div`
  margin-top: ${spacing.M};
`
